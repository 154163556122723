import axios from 'axios';
import { CONFIG } from '../constants';
import { HubConnectionBuilder } from '@microsoft/signalr';

let agent_url = CONFIG.API_AGENT_UAT;
let client_url = CONFIG.API_CLIENT_UAT;
let domain_url = CONFIG.API_DOMAIN;

const getDomainApiKey = () => {
  let apiKey = [CONFIG.API_DOMAIN_KEY1, CONFIG.API_DOMAIN_KEY2, CONFIG.API_DOMAIN_KEY3];
  let retKey = apiKey[Math.round(Math.random() * 2)];
  return retKey;
};

const domainService = axios.create({
  baseURL: domain_url,
  timeout: 30000, // 请求超时时间
  headers: { 'X-Api-Key': getDomainApiKey() },
});

export const domainApiGet = (url) => {
  return new Promise((resolve, reject) => {
    domainService.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  });
};

const codeMessage = {
  200: 'The server successfully returned the requested data.',
  201: 'Data is created or modified successfully. ',
  202: 'The server has accepted the request but has not yet processed it. A request has been queued in the background (asynchronous task).',
  204: 'The server successfully processed the request, but did not return anything.',
  400: 'There was an error in the request, the server does not create or modify data.',
  401: 'User does not have authorization(wrong token, username, password).',
  403: 'The server rejected the request.',
  404: 'The request is made for a record that does not exist, no operation is performed on the server.',
  405: 'The specified method in the request line cannot be used to request the corresponding resource.',
  406: 'unavailable requested format.',
  410: 'The requested resource is permanently deleted and will not be retrieved.',
  422: 'A validation error occurred while creating an object.',
  500: 'An error occurred on the server. Please check the server.',
  502: 'Bad gateway.',
  503: 'The service is unavailable due to temporarily overloaded or maintained.',
  504: 'Gateway Time-out.',
  505: 'The server does not support the HTTP protocol version used in the request.',
};

const service = axios.create({
  baseURL: agent_url,
  timeout: 30000, // 请求超时时间
  headers: { 'esb-ss-aid': localStorage.getItem('session') },
});

service.interceptors.request.use(
  (config) => {
    // 此处可在请求发起前对请求进行处理例如添加Token操作
    if (config.method === 'get') {
      // const t = new Date().getTime(); //为get请求添加时间戳，防止缓存问题
      // config.params.t = t;
    }
    return config;
  },
  (error) => {
    // 请求发生错误时可在此处处理
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // 此处可在统一处理请求回的数据
    return response;
  },
  (error) => {
    // 请求发生错误时可在此处处理
    if (error.response.status === 401) {
      localStorage.removeItem('session');
      localStorage.removeItem('userId');
      localStorage.removeItem('user');
      if (window.location.href.indexOf('/manage/unclaim') > 0) {
        window.location.href = '/login?redirect=/manage/unclaim';
        return;
      }
      setTimeout(() => (window.location.href = '/error/401'), 600);
      return;
    }
    return Promise.reject(error);
  }
);

export const request = (params) => {
  return new Promise((resolve, reject) => {
    service(params)
      .then((response) => {
        const errorText = codeMessage[response.status] || 'Unknown request error.';
        if (response.status === 200 || response.status === 204) {
          /* TODO:在实际业务中此处可根据与后端的约定状态码处理返回数据 */
          resolve(response);
          /* if (response.data.code === 0) {
            resolve(response.data.data);
          } else {
            reject(response.data.data);
          }*/
        } else {
          reject(errorText);
          const error = new Error(errorText);
          error.name = `${response.status}`;
          throw error;
        }
      })
      .catch((error) => {
        const errorText = codeMessage[error.response.status] || 'Unknown request error.';
        error.message = errorText;
        reject(error);
        // reject(error && error.response ? error.response.status +' '+ error.response.data + ' ' + error.message : error);
      });
  });
};

export const originalRequest = (params) => {
  return service(params);
};

// axios.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//   error.globalErrorProcess = function () {
//     let status = this.response.status;
//     let {message} = error;
//     console.log(status,message)
//     switch (status) {
//         case 401: // 处理基本 401 错误
//             break;
//         case 404: // 处理基本 404 错误
//             break;
//         case 403: // 处理基本 403 错误
//             break;
//                   // 处理其他4xx/5xx等基本错误的处理
//         default:
//           break;
//     }
//     return Promise.reject(this,message);
//   };
//   // if(error.config.hasOwnProperty('catch') && error.config.catch === true) {
//   //     return Promise.reject(error);
//   // }
//   return error.globalErrorProcess()
// });

export const apiPost = (url, data) => {
  return new Promise((resolve, reject) => {
    let config = {
      headers: {
        'esb-ss-aid': localStorage.getItem('session'),
      },
    };
    axios.post(url, data, config).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        // error.globalErrorProcess();
        return Promise.reject(error);
      }
    );
  });
};

export const getOldChatByEsbIDAndBuyerID = (esb_id, buyer_id, sort, page) => {
  let _url = '/signboard/' + esb_id + '/buyer/' + buyer_id + '/chat/old?sortAsc=' + sort;
  if (page) {
    _url = _url + '&page=' + page;
  }
  return request({
    method: 'get',
    url: _url,
  });
};

export const getNewChatByEsbIDAndBuyerID = (esb_id, buyer_id, sort) => {
  let _url = '/signboard/' + esb_id + '/buyer/' + buyer_id + '/chat/new?sortAsc=' + sort;
  return request({
    method: 'get',
    url: _url,
  });
};

export const postChatById = (esb_id, buyer_id, message) => {
  let _url = '/signboard/' + esb_id + '/buyer/' + buyer_id + '/chat';
  return request({
    method: 'post',
    url: _url,
    data: {
      message: message,
      messageType: 0,
    },
  });
};

export const register = (email, password) => {
  let _url = '/register';
  return request({
    method: 'post',
    url: _url,
    data: { email: email, password: password },
  });
};

export const login = (email, password) => {
  return request({
    method: 'post',
    url: '/login',
    data: { email: email, password: password },
  });
};

export const postAgentActivate = (agent_id, profile) => {
  let _url = '/activate/' + agent_id;
  return request({
    method: 'post',
    url: _url,
    data: profile,
  });
};

export const getAgentActivateById = (agent_id) => {
  let _url = '/activate/' + agent_id;
  return request({
    method: 'get',
    url: _url,
    data: {},
  });
};

export const getSignboards = (isSold, isActive, filter, isUnconfirmed = 'false') => {
  let _url = '?filter=' + filter;
  if (isSold) {
    _url = _url + '&isSold=' + isSold;
  }
  if (isActive) {
    _url = _url + '&isActive=' + isActive;
  }
  if (isUnconfirmed) {
    _url = _url + '&isUnconfirmed=' + isUnconfirmed;
  }
  _url = '/signboard' + _url;
  return request({
    method: 'get',
    url: _url,
  });
};

export const logout = () => {
  let _url = '/logout';
  return request({
    method: 'post',
    url: _url,
  });
};

export const getUser = () => {
  let _url = '/profile';
  return request({
    method: 'get',
    url: _url,
  });
};

export const postProfile = (profile) => {
  let _url = '/profile';
  return request({
    method: 'post',
    url: _url,
    data: { profile: profile },
  });
};

export const postProfileImage = (formData) => {
  let _url = '/profile/image';
  return request({
    method: 'post',
    url: _url,
    data: formData,
  });
};

export const postUserPassword = (oldPassword, newPassword) => {
  let _url = '/profile/password';
  return request({
    method: 'post',
    url: _url,
    data: {
      oldPassword: oldPassword,
      newPassword: newPassword,
    },
  });
};

export const postSignboardDeactivate = (id) => {
  let _url = '/signboard/' + id + '/deactivate';
  return request({
    method: 'post',
    url: _url,
    data: {},
  });
};

export const getSignboardBuyers = (esb_id, isFavourite, filter) => {
  let _url = '/signboard/' + esb_id + '/buyer' + '?filter=' + filter;
  if (isFavourite) {
    _url = _url + '&isFavourite=' + isFavourite;
  }
  return request({
    method: 'get',
    url: _url,
    data: {},
  });
};

export const getSignboardById = (esb_id) => {
  let _url = '/signboard/' + esb_id;
  return request({
    method: 'get',
    url: _url,
    data: {},
  });
};

export const getSignboardUnread = (esb_id) => {
  let _url = '/signboard/' + esb_id + '/chat/unread-count';
  return request({
    method: 'get',
    url: _url,
    data: {},
  });
};

export const getSignboardBuyerUnread = (esb_id, buyer_id) => {
  let _url = '/signboard/' + esb_id + '/buyer/' + buyer_id + '/chat/unread-count';
  return request({
    method: 'get',
    url: _url,
    data: {},
  });
};

export const updateChatRead = (esb_id, chat_id) => {
  let _url = '/signboard/' + esb_id + '/chat/' + chat_id + '/read';
  return request({
    method: 'put',
    url: _url,
    data: {},
  });
};

export const getSignboardSummary = (esb_id) => {
  let _url = '/signboard/' + esb_id + '/summary';
  return request({
    method: 'get',
    url: _url,
    data: {},
  });
};

export const newSignboard = (profile) => {
  let _url = '/signboard';
  return request({
    method: 'post',
    url: _url,
    data: profile,
  });
};

export const updateSignboard = (id, profile) => {
  let _url = '/signboard/' + id + '/update';
  return request({
    method: 'post',
    url: _url,
    data: profile,
  });
};

export const postSignboardImage = (id, url) => {
  let _url = '/signboard/' + id + '/image?url=' + url;
  return request({
    method: 'post',
    url: _url,
    // data:profile
  });
};

export const postSignboardImageBatch = (id, urls) => {
  let _url = '/signboard/' + id + '/image/batch';
  return request({
    method: 'post',
    url: _url,
    data: urls,
  });
};

export const getChatByEsbIDAndBuyerID = (esb_id, buyer_id, sort, page) => {
  let _url = '/signboard/' + esb_id + '/buyer/' + buyer_id + '/chat?sortAsc=' + sort;
  if (page) {
    _url = _url + '&page=' + page;
  }
  return request({
    method: 'get',
    url: _url,
    data: {},
  });
};

export const getSuggest = (terms) => {
  let url = domain_url + 'v1/properties/_suggest?terms=' + terms + '&channel=all&pageSize=5';
  return domainApiGet(url);
};

export const getProperties = (id) => {
  let url = domain_url + 'v1/properties/' + id;
  return domainApiGet(url);
};

export const postSignboardRemove = (id) => {
  let _url = `/signboard/${id}/remove`;
  return request({
    method: 'post',
    url: _url,
    data: {},
  });
};

export const postSignboardActivate = (id) => {
  let _url = `/signboard/${id}/activate`;
  return request({
    method: 'post',
    url: _url,
    data: {},
  });
};

export const postSignboardLink = (id, title, url) => {
  let _url = '/signboard/' + id + '/link';
  return request({
    method: 'post',
    url: _url,
    data: { url: url, title: title },
  });
};

export const postBuyerInterestLevel = (esb_id, buyer_id, interestLevel) => {
  let _url = '/signboard/' + esb_id + '/buyer/' + buyer_id + '/interestLevel?interestLevel=' + interestLevel;
  return request({
    method: 'post',
    url: _url,
    // data: {interestLevel: interestLevel},
  });
};

export const postBuyerBudget = (esb_id, buyer_id, budget_low, budget_high) => {
  let _url = '/signboard/' + esb_id + '/buyer/' + buyer_id + '/budget?low=' + budget_low + '&high=' + budget_high;
  return request({
    method: 'post',
    url: _url,
    // data: {low: budget_low, high: budget_high},
  });
};

export const postBuyerNote = (esb_id, buyer_id, note) => {
  let _url = '/signboard/' + esb_id + '/buyer/' + buyer_id + '/note?note=' + note;
  return request({
    method: 'post',
    url: _url,
    // data: {note: note},
  });
};

export const getBuyerByEsbidAndBuyerid = (esb_id, buyer_id) => {
  let _url = '/signboard/' + esb_id + '/buyer/' + buyer_id;
  return request({
    method: 'get',
    url: _url,
    data: {},
  });
};

export const getImagesById = (esb_id) => {
  let _url = '/signboard/' + esb_id + '/image';
  return request({
    method: 'get',
    url: _url,
  });
};

export const getClients = () => {
  let _url = '/buyer';
  return request({
    method: 'get',
    url: _url,
  });
};

export const getClientSignboards = (bid) => {
  let _url = '/buyer/' + bid;
  return request({
    method: 'get',
    url: _url,
  });
};

export const postSignboardClaim = (esb_id, answer) => {
  let _url = '/signboard/' + esb_id + '/claim/' + answer;
  return request({
    method: 'post',
    url: _url,
    data: {},
  });
};

export const getCredit = () => {
  let _url = '/credit';
  return request({
    method: 'get',
    url: _url,
  });
};

export const postCredit = (creditpack) => {
  let _url = '/credit';
  return request({
    method: 'post',
    url: _url,
    data: creditpack,
  });
};

// Featured property
export const newFeaturedProperty = (profile) => {
  let _url = '/property/featured';
  return request({
    method: 'post',
    url: _url,
    data: profile,
  });
};

export const getFeaturedPropertyById = (pid) => {
  let _url = '/property/featured/' + pid;
  return request({
    method: 'get',
    url: _url,
  });
};

export const updateFeaturedProperty = (pid, profile) => {
  let _url = '/property/featured/' + pid;
  return request({
    method: 'put',
    url: _url,
    data: profile,
  });
};

export const getFeaturedPropertyImages = (pid) => {
  let _url = '/property/featured/' + pid + '/image';
  return request({
    method: 'get',
    url: _url,
  });
};

export const addFeaturedPropertyImage = (pid, file) => {
  let _url = '/property/feature/' + pid + '/image';
  const formData = new FormData();
  if (file) {
    formData.append('files', file, file.name);
  }

  return request({
    method: 'post',
    url: _url,
    data: formData,
  });
};

export const removeFeaturedPropertyImage = (pid, iid) => {
  let _url = '/property/feature/' + pid + '/image/' + iid;
  return request({
    method: 'delete',
    url: _url,
  });
};

export const agentHubConnection = () => {
  return new HubConnectionBuilder()
    .withUrl(agent_url + 'hubs/message')
    .withAutomaticReconnect()
    .build();
};

export const clientHubConnection = () => {
  return new HubConnectionBuilder()
    .withUrl(client_url + 'hubs/message')
    .withAutomaticReconnect()
    .build();
};

export const agentHubPostMessage = async (esb_id, buyer_id, agent_id, message) => {
  let _url = `signboard/${esb_id}/buyer/${buyer_id}/agent/${agent_id}/messages`;
  return request({
    method: 'post',
    url: _url,
    data: {
      message: message,
      messageType: 0,
    },
  });
};

export const clientHubPostMessage = async (esb_id, buyer_id, agent_id, message) => {
  let _url = `signboard/${esb_id}/buyer/${buyer_id}/agent/${agent_id}/messages`;
  return fetch(client_url + _url, {
    method: 'POST',
    body: JSON.stringify({ message: message, messageType: 0 }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
