import { createSlice } from '@reduxjs/toolkit';

//Creating First Init State
const initState = {
  shareMenu: false,
  settingsMenu: false,
  highlightMenu: false,
  backgroundMenu: false,
};

// Creating First Slice
const menuReducer = createSlice({
  name: 'settingsActions',
  initialState: initState,
  //Reducers become functions you access from consumer
  reducers: {
    SETTIGNS_MENU(state) {
      state.settingsMenu = !state.settingsMenu;
      state.highlightMenu = false;
      state.backgroundMenu = false;
    },
    HIGHLIGHT_MENU(state) {
      state.highlightMenu = !state.highlightMenu;
      state.settingsMenu = false;
      state.backgroundMenu = false;
    },
    BACKGROUND_MENU(state) {
      state.backgroundMenu = !state.backgroundMenu;
      state.highlightMenu = false;
      state.settingsMenu = false;
    },
    SHARE_MENU(state) {
      state.shareMenu = true;
      state.backgroundMenu = false;
      state.highlightMenu = false;
      state.settingsMenu = false;
    },
    CLOSE_MENU(state) {
      state.backgroundMenu = false;
      state.highlightMenu = false;
      state.settingsMenu = false;
      state.shareMenu = false;
    },
  },
});

export const settingsActions = menuReducer.actions;
//Add Reducer at the end, this is needed so you can import it in the mainStore
export default menuReducer.reducer;
