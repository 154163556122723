import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { darkActions } from '../Reducers/dark-slice';

const useDarkMode = () => {
  let localTheme = localStorage.getItem('Sticky-Theme');

  const dispatch = useDispatch();
  let darkState = useSelector((state) => state.darkReducer.darkMode);

  //Functions with Transition Disabled, for instant Switch Mode
  const darkCall = () => {
    dispatch(darkActions.DARK_MODE());
    setTimeout(function () {
      document.body.classList.add('theme-dark');
      document.body.classList.remove('theme-light');
    }, 50);
    document.body.classList.add('no-transition');
    setTimeout(function () {
      document.body.classList.remove('no-transition');
    }, 350);
  };
  const lightCall = () => {
    dispatch(darkActions.LIGHT_MODE());
    setTimeout(function () {
      document.body.classList.add('theme-light');
      document.body.classList.remove('theme-dark');
    }, 50);
    document.body.classList.add('no-transition');
    setTimeout(function () {
      document.body.classList.remove('no-transition');
    }, 350);
  };

  const setDarkState = (e) => {
    if (e === true || e === false) {
      if (darkState === false) {
        localStorage.setItem('Sticky-Theme', 'theme-dark');
        darkCall();
      }
      if (darkState === true) {
        localStorage.setItem('Sticky-Theme', 'theme-light');
        lightCall();
      }
    }
    if (e === 'auto') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches === true) {
        darkCall();
      } else {
        lightCall();
      }
      dispatch(darkActions.AUTO_MODE());
      localStorage.setItem('Sticky-Theme', 'auto');
    }
  };

  //Local Storage Check
  useEffect(() => {
    const localLight = () => {
      dispatch(darkActions.LIGHT_MODE());
      document.body.classList.add('theme-light');
      document.body.classList.remove('theme-dark');
    };
    const localDark = () => {
      dispatch(darkActions.DARK_MODE());
      document.body.classList.add('theme-dark');
      document.body.classList.remove('theme-light');
    };
    if (localTheme) {
      if (localTheme === 'theme-dark') {
        localDark();
      }
      if (localTheme === 'theme-light') {
        localLight();
      }
      if (localTheme === 'auto') {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches === true) {
          localDark();
        } else {
          localLight();
        }
      }
    } else {
      if (darkState === true) {
        localDark();
      }
      if (darkState === false) {
        localLight();
      }
    }
  }, [localTheme, darkState, dispatch]);

  return [darkState, setDarkState];
};

export default useDarkMode;
