const useShare = (service, shareURL, shareTitle) => {
  let URL;
  if (service === 'facebook') {
    URL = 'https://www.facebook.com/sharer/sharer.php?u=' + shareURL;
  } else if (service === 'twitter'){
      URL = 'https://twitter.com/share?text=' + shareTitle
  } else if (service === 'pinterest'){
      URL = 'https://pinterest.com/pin/create/button/?url=' + shareURL
  } else if (service === 'linkedin'){
      URL = 'https://www.linkedin.com/shareArticle?mini=true&url=' + shareURL
  } else  if(service ==='whatsapp'){
      URL = 'whatsapp://send?text=' + shareTitle + ' ' + shareURL
  } else  if(service === 'email'){
      URL = 'mailto:?subject=' + shareTitle + '&body=' + shareURL + ''
  }
  return [URL];
};

export default useShare;
