import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { settingsActions } from '../../Services/Reducers/setting-slice';
import { Offcanvas } from 'react-bootstrap';
import HighlightMenu from './HighlightMenu';
import BackgroundMenu from './BackgroundMenu';
import MenuTitle from '../../Comon/MenuTitle';
import Divider from '../../Components/Dividers/Divider';
import LinkList, { ButtonItem, SwitchItem } from '../../Components/Linklist/LinkList';
import useDarkMode from '../../Services/Hooks/useDarkMode';

const Settings = (props) => {
  const dispatch = useDispatch();
  const [toggleDarkMode, setDarkState] = useDarkMode();

  const settingsSelector = useSelector((state) => state.menuReducer.settingsMenu);
  const backgroundSelector = useSelector((state) => state.menuReducer.backgroundMenu);
  const highlightSelector = useSelector((state) => state.menuReducer.highlightMenu);
  let defaultCheckedDark = useSelector((state) => state.darkReducer.darkMode);
  const darkSwitchHandler = () => {
    setDarkState(!toggleDarkMode);
  };

  const closeMenuHandler = () => {
    dispatch(settingsActions.CLOSE_MENU());
  };
  const showSettingsMenu = () => {
    dispatch(settingsActions.SETTIGNS_MENU());
  };
  const showBackgroundMenu = () => {
    dispatch(settingsActions.BACKGROUND_MENU());
  };
  const showHighlightMenu = () => {
    dispatch(settingsActions.HIGHLIGHT_MENU());
  };

  return (
    <>
      <Offcanvas show={settingsSelector} onHide={closeMenuHandler} placement="bottom" className="mx-3 mb-3 p-3 rounded-m">
        <MenuTitle title="Settings" text="Flexible and Easy to Use" closeMenuHandler={closeMenuHandler} />
        <Divider className="mt-2 mb-1" />
        <div className="ms-n1 mb-n3">
          <LinkList className="list-custom-small">
            <SwitchItem onClick={darkSwitchHandler} checked={defaultCheckedDark} id="dark" icon="fa fa-moon bg-red-dark rounded-s me-3" title="Dark Mode" className="py-2" />
          </LinkList>
          <LinkList className="list-custom-large">
            <ButtonItem onClick={showHighlightMenu} id="dark" icon="fa fa-tint bg-green-dark rounded-s" title="Page Highlights" text="16 Color Highlights Inclduded" badge="HOT" />
            <ButtonItem onClick={showBackgroundMenu} id="dark" icon="fa fa-cog bg-blue-dark rounded-s" title="Background Color" text="10 Page Gradients Included" badge="NEW" className="border-0"/>
          </LinkList>
        </div>
      </Offcanvas>
      <HighlightMenu highlightSelector={highlightSelector} closeMenuHandler={closeMenuHandler} showSettingsMenu={showSettingsMenu} />
      <BackgroundMenu backgroundSelector={backgroundSelector} closeMenuHandler={closeMenuHandler} showSettingsMenu={showSettingsMenu} />
    </>
  );
};

export default Settings;
