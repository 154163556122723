import { createSlice } from '@reduxjs/toolkit';

let autoTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

//Creating First Init State
const initState = {
  darkMode: false,
};

// Creating First Slice
const darkReducer = createSlice({
  name: 'darkActions',
  initialState: initState,
  //Reducers become functions you access from consumer
  reducers: {
    TOGGLE(state) {
      state.darkMode = !state.darkMode;
    },
    DARK_MODE(state) {
      state.darkMode = true;
    },
    LIGHT_MODE(state) {
      state.darkMode = false;
    },
    AUTO_MODE(state) {
      state.darkMode = autoTheme;
    },
  },
});

export const darkActions = darkReducer.actions;
//Add Reducer at the end, this is needed so you can import it in the mainStore
export default darkReducer.reducer;
