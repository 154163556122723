export const CONFIG = {
    AGENT_LOCAL_HTTP: 'https://localhost:3001/',
    BUYER_LOCAL_HTTP: 'https://localhost:3000/',

    WEBSITE_HTTP: 'https://www.esignboard.com.au/',
    AGENT_HTTP: 'https://agent.esignboard.au',
    BUYER_HTTP: 'https://buyer.esignboard.au',

    API_AGENT_LOCAL: 'https://localhost:7075/',
    API_AGENT_DEV: 'https://esignboard-agent-api-dev.azurewebsites.net/',
    API_AGENT_UAT: 'https://es-uat-agent-api.azurewebsites.net/',
    API_AGENT_PROD: '',
    
    API_CLIENT_LOCAL: 'https://localhost:7000/',
    API_CLIENT_DEV: 'https://esignboard-api-dev.azurewebsites.net/',
    API_CLIENT_UAT: 'https://es-uat-buyer-api.azurewebsites.net/',
    API_CLIENT_PROD: '',

    API_DOMAIN: 'https://api.domain.com.au/',
    API_DOMAIN_KEY1: 'key_a36a103b00413225fca9f7f8d693e2ea',
    API_DOMAIN_KEY2: 'key_b2ab025f00029c86e140b34c1da2b58c',
    API_DOMAIN_KEY3: 'key_683fd0d040229353f79a1d5f7e079fa9',

    API_QRCODE_BASE: 'https://api.qrserver.com/v1/create-qr-code/',

    GA_UAT_AGENT_MEASUREMENT_ID: 'G-WXQ7FLLC20',
    GA_PROD_AGENT_MEASUREMENT_ID: '',

    PAYPAL_CLIENT_ID_KONTACT: 'AUKLBR2ZroO0r31_rSgrpPVmBebPSnNchjM0f9gl9iYbWt4Lh-ldf49ICeBtH-m-mWFqi0STSExNuoRf',
    PAYPAL_CLIENT_ID_SITEDISKS: 'AW0sUgKojbAVdvoEveHEJAdFDIo1aGk-3CycP1IZQw_KPSvhABbk45UDATuffi8FwWcBNv0rmoiQWhCx'
}